<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div
          class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl h-screen p-2"
        >
          <div class="mt-0">
            <img
              class="w-full mt-5"
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1126454141745643571/POD_Banner_Join_Us.png"
            />
            <div class="text-center">
              <h3 class="text-5xl font-sans font-bold text-blue-900 mt-10">
                Contact us!
              </h3>
              <h3 class="text-xl font-bold mt-10">
                If you have any questions or would like to chat with us, send us
                an email to:
              </h3>
              <h3 class="text-3xl font-sans font-bold text-blue-900 mt-10">
                contact@craftmasters.com
              </h3>
            </div>
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      searchPrompt: null,
      slideList: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126410892519682129/POD_Banner_for_web_02.png",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126407023160987708/POD_Banner_for_web_01.png",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126425212389445673/POD_Banner_for_web_03.png",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
